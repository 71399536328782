<template>
  <div class="card shadow-sm">
    <form id="formItems" class="needs-validation" novalidate>
      <div class="card-header">
        <h3 class="card-title" style="font-size: 20px; font-weight: bold">
          แก้ไขสิทธิ์ผู้ใช้งาน
        </h3>
      </div>
      <!-- form -->
      <div class="card-body">
        <!-- -->

        <div class="row d-flex justify-content-center mb-4">
          <label
            for="secondaryProductCode"
            class="required form-label col-sm-10"
            >group name</label
          >
          <div class="col-sm-10">
            <input
              v-model="form.groupName"
              type="text"
              class="form-control"
              name="secondaryProductCode"
              id="secondaryProductCode"
              required
            />
          </div>
        </div>
        <div class="card-body" style="padding-top: 20px">
          <div class="table-responsive">
            <a-table :dataSource="dataItemsShow" :columns="columns">
              <template #bodyCell="{ column, record, index }">
                <template v-if="column.dataIndex === 'no'">
                  {{ record.no }}
                </template>
                <template v-if="column.dataIndex === 'masterMenu'">
                  <span>
                    {{ record.masterMenu.menuName }}
                  </span>
                </template>
                <template v-if="column.dataIndex === 'canView'">
                  <el-checkbox
                    type="checkbox"
                    v-model="record.isCanView"
                    @change="changeValue(record)"
                  />
                </template>
                <template v-if="column.dataIndex === 'canCreate'">
                  <el-checkbox
                    type="checkbox"
                    :disabled="record.isCanView == false"
                    v-model="record.isCanCreate"
                    @change="changeValue(record)"
                  />
                </template>
                <template v-if="column.dataIndex === 'canModified'">
                  <el-checkbox
                    type="checkbox"
                    :disabled="record.isCanView == false"
                    v-model="record.isCanModified"
                    @change="changeValue(record)"
                  />
                </template>
                <template v-if="column.dataIndex === 'canDelete'">
                  <el-checkbox
                    type="checkbox"
                    :disabled="record.isCanView == false"
                    v-model="record.isCanDelete"
                    @change="changeValue(record)"
                  />
                </template>
              </template>
            </a-table>
          </div>
        </div>
      </div>

      <hr class="pt-0 mt-0" style="color: LightGrey" />
      <div class="card-header">
        <h3 class="" style="font-size: 20px; font-weight: bold">
          {{ $t("manageuser") }}
        </h3>
      </div>
      <div class="card-body">
        <div class="card-toolbar row justify-content-center">
          <div
            class="col-sm-5"
            style="
              border: solid lightgray 0.5px;
              padding: 20px;
              border-radius: 10px;
            "
          >
            <b><u>USER ทั้งหมด</u></b>
            <div>
              <el-checkbox
                type="checkbox"
                v-model="allLeftCheckbox"
                @change="checkAllitemsleftArray()"
              />
              <span class="form-check-label"> เลือกทั้งหมด </span>
            </div>
            <div
              v-for="van in itemsleftArray"
              :key="van"
              class="col-sm-12 me-1"
            >
              <label
                class="
                  form-check form-check-sm form-check-custom form-check-solid
                  me-5
                  mb-2
                  mt-2
                "
              >
                <el-checkbox type="checkbox" v-model="van.isSelected" />
                <span class="form-check-label">
                  {{ van.username }}
                </span>
              </label>
            </div>
          </div>
          <div class="col-sm-1" style="align-self: center; text-align: center">
            <button
              type="button"
              class="btn btn-light btn-primary"
              style="font-size: 14px; font-weight: bold"
              @click="appendRightArray()"
            >
              <i class="fas fa-arrow-right"></i>
            </button>
            <button
              type="button"
              class="btn btn-light btn-danger"
              style="font-size: 14px; font-weight: bold; margin-top: 10px"
              @click="appendLeftArray()"
            >
              <i class="fas fa-arrow-left"></i>
            </button>
          </div>
          <div
            class="col-sm-5 ms-1"
            style="
              border: solid lightgray 0.5px;
              padding: 20px;
              border-radius: 10px;
            "
          >
            <b><u>USER ที่เลือก</u></b>
            <div>
              <el-checkbox
                type="checkbox"
                v-model="allRightCheckbox"
                @change="checkAllitemsrightArray()"
              />
              <span class="form-check-label"> เลือกทั้งหมด </span>
            </div>
            <div
              v-for="van in itemsrightArray"
              :key="van"
              class="col-sm-12 me-1"
            >
              <label
                class="
                  form-check form-check-sm form-check-custom form-check-solid
                  me-5
                  mb-2
                  mt-2
                "
              >
                <el-checkbox type="checkbox" v-model="van.isSelected" />
                <span class="form-check-label">
                  {{ van.username }}
                </span>
              </label>
            </div>
          </div>
          <div></div>
        </div>
      </div>

      <footer class="m-6">
        <div class="card-toolbar row justify-content-center">
          <div class="col-sm-11">
            <button
              type="submit"
              class="btn btn-sm btn-light btn-primary ms-3 me-2"
              style="font-size: 14px; font-weight: bold"
              @click="submit()"
              :disabled="loading"
            >
              <div
                v-if="loading"
                class="spinner-grow spinner-grow-sm text-light"
                role="status"
              ></div>
              {{ $t("save") }}
            </button>
            <button
              type="button"
              class="btn btn-sm btn-light"
              style="font-size: 14px; font-weight: bold"
              @click="cancel()"
            >
              {{ $t("cancel") }}
            </button>
          </div>
        </div>
      </footer>
    </form>
  </div>
</template>

<script>
import { Decode, Encode } from "@/services";
import userApi from "@/api/user/";
import whApi from "@/api/warehouse/";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

export default {
  setup() {
    document.title = "J&N | เพิ่มประเภทสินค้า";
  },
  data: () => ({
    loading: false,
    isSubmit: false,

    form: {
      groupName: "",
    },
    dataItemsShow: [],
    columns: [
      { title: "no", dataIndex: "no" },
      { title: "masterMenu", dataIndex: "masterMenu" },
      { title: "View", dataIndex: "canView",width:100,align:'center' },
      { title: "Create", dataIndex: "canCreate",width:100,align:'center' },
      { title: "Modified", dataIndex: "canModified",width:100,align:'center' },
      { title: "Delete", dataIndex: "canDelete",width:100,align:'center' },
    ],
    userGroupId: null,
    itemsleftArray: [],
    itemsrightArray: [],

    ccsSelect: `background-size: 16px 12px;background-position: right 1rem center;background-repeat: no-repeat;background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%237E8299' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e")`,
  }),
  watch: {
    // "form.companyId"(val) {
    //   console.log("form.companyId", val);
    //   this.getAllBranch(val);
    // },
    // "form.branchId"(val) {
    //   this.getAllWarehouse(val);
    // },
  },
  async created() {
    await this.getOne();
    await this.getAllAvalibleUsers();
    await this.getAllUsers();

    // this.getAllCompany();
    // this.getAllWarehouse();
  },

  methods: {
    checkAllitemsleftArray() {
      if (this.allLeftCheckbox) {
        this.itemsleftArray.forEach((element) => {
          element.isSelected = true;
        });
      } else {
        this.itemsleftArray.forEach((element) => {
          element.isSelected = false;
        });
      }
    },
    checkAllitemsrightArray() {
      if (this.allRightCheckbox) {
        this.itemsrightArray.forEach((element) => {
          element.isSelected = true;
        });
      } else {
        this.itemsrightArray.forEach((element) => {
          element.isSelected = false;
        });
      }
    },
    appendRightArray() {
      this.itemsleftArray.forEach((element) => {
        if (element.isSelected == true) {
          let a = {
            ...element,
          };
          a.isSelected = false;
          console.log("aaaaaaaaaa", a);
          this.itemsrightArray.push(a);
        }
      });
      this.itemsleftArray = this.itemsleftArray.filter(
        (item) => item.isSelected !== true
      );
      this.allLeftCheckbox = false;
    },
    appendLeftArray() {
      this.itemsrightArray.forEach((element) => {
        if (element.isSelected == true) {
          let a = {
            ...element,
          };
          a.isSelected = false;
          this.itemsleftArray.push(a);
        }
        this.itemsrightArray = this.itemsrightArray.filter(
          (item) => item.isSelected !== true
        );
      });
      this.allRightCheckbox = false;
    },
    async getAllAvalibleUsers() {
      this.loading = true;
      let responseData = [];
      let companyId = localStorage.getItem("companyId");
      let userGroupId = this.$route.query.id;
      try {
        // responseData = await whApi.van.getAll();
        responseData = await userApi.usergroup.getAvalibleUsers(
          companyId,
          userGroupId
        );
      } catch (error) {
        console.log(error);
      }
      if (responseData.response_status === "SUCCESS") {
        this.itemsleftArray = responseData.data;
        console.log("this.itemsleftArray", this.itemsleftArray);
        this.loading = false;
      } else {
        this.loading = false;
        Swal.fire({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: "ดึงข้อมูลไม่สำเร็จ กรุณาตรวจสอบ SERVER",
        });
      }
    },
    async getAllUsers() {
      this.loading = true;
      let responseData = [];
      let companyId = localStorage.getItem("companyId");
      let userGroupId = this.$route.query.id;
      try {
        // responseData = await whApi.van.getAll();
        responseData = await userApi.user.getAllUserGroup(
          companyId,
          userGroupId
        );
      } catch (error) {
        console.log(error);
      }
      if (responseData.response_status === "SUCCESS") {
        this.itemsrightArray = responseData.data;
        console.log("this.itemsrightArray", this.itemsrightArray);
        this.loading = false;
      } else {
        this.loading = false;
        Swal.fire({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: "ดึงข้อมูลไม่สำเร็จ กรุณาตรวจสอบ SERVER",
        });
      }
    },
    async changeValue(val) {
      if (val.isCanView == false) {
        val.isCanCreate = false;
        val.isCanDelete = false;
        val.isCanModified = false;
      }
      // console.log("change", val);
      let responseData = [];
      let data = {
        isCanCreate: val.isCanCreate,
        isCanDelete: val.isCanDelete,
        isCanModified: val.isCanModified,
        isCanView: val.isCanView,
      };
      try {
        responseData = await userApi.usergroupitem.update(val.id, data);
      } catch (error) {
        console.log(error);
      }
      if (responseData.response_status === "SUCCESS") {
        Swal.fire({
          icon: "success",
          title: "อัพเดตข้อมูลสำเร็จ",
          showConfirmButton: false,
          timer: 1500,
          // text: "ดึงข้อมูลไม่สำเร็จ กรุณาตรวจสอบ SERVER",
        });
        this.loading = false;
        await this.getOneItem();
      } else {
        this.loading = false;
        Swal.fire({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: "ดึงข้อมูลไม่สำเร็จ กรุณาตรวจสอบ SERVER",
        });
      }
    },
    async getOne() {
      this.loading = true;
      this.$route.query.id;
      let responseData = [];
      try {
        responseData = await userApi.usergroup.getOne(this.$route.query.id);
      } catch (error) {
        console.log(error);
      }
      if (responseData.response_status === "SUCCESS") {
        this.form = responseData.data;
        this.userGroupId = this.form.id;
        this.loading = false;
        await this.getOneItem();
      } else {
        this.loading = false;
        Swal.fire({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: "ดึงข้อมูลไม่สำเร็จ กรุณาตรวจสอบ SERVER",
        });
      }
    },
    async getOneItem() {
      this.loading = true;
      this.$route.query.id;
      let responseData = [];
      try {
        responseData = await userApi.usergroupitem.getAll(this.userGroupId);
      } catch (error) {
        console.log(error);
      }
      if (responseData.response_status === "SUCCESS") {
        this.dataItemsShow = responseData.data;
        this.dataItemsShow.forEach((element, index) => {
          element.no = index + 1;
        });
        console.log("this.dataItemsShow", this.dataItemsShow);
        this.loading = false;
      } else {
        this.loading = false;
        Swal.fire({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: "ดึงข้อมูลไม่สำเร็จ กรุณาตรวจสอบ SERVER",
        });
      }
    },
    async getAllCompany() {
      this.loading = true;
      let responseData = [];
      try {
        responseData = await userApi.company.getAll();
      } catch (error) {
        console.log(error);
      }
      if (responseData.response_status === "SUCCESS") {
        this.companyItems = responseData.data;
        this.form.companyId = responseData.data[0].id;
      }
      this.loading = false;
    },
    async getAllBranch(companyId) {
      this.loading = true;
      let responseData = [];
      try {
        responseData = await whApi.branch.getAll(companyId);
      } catch (error) {
        console.log(error);
      }
      if (responseData.response_status === "SUCCESS") {
        this.branchItems = responseData.data;
      }
      this.loading = false;
    },
    async getAllWarehouse(branchId) {
      this.loading = true;
      let responseData = [];
      try {
        responseData = await whApi.warehouse.getAll(branchId);
      } catch (error) {
        console.log(error);
      }
      if (responseData.response_status === "SUCCESS") {
        this.warehouseItems = responseData.data;

        this.loading = false;
      } else {
        this.loading = false;
        Swal.fire({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: "ดึงข้อมูลไม่สำเร็จ กรุณาตรวจสอบ SERVER",
        });
      }
    },
    async submit() {
      let createResponse = [];

      this.loading = true;
      let users = [];

      this.itemsrightArray.forEach((element) => {
        console.log("element", element);
        let dataUsers = {
          id: element.id,
        };
        users.push(dataUsers);
      });

      this.form = {
        // ...this.form,
        userGroupId: this.form.id,
        users: users,
        // companyId: parseInt(localStorage.getItem("companyId")),
      };

      console.log("this.form", this.form);
      createResponse = await userApi.usergroup.addUserGroup(this.form);
      if (createResponse.response_status === "SUCCESS") {
        Swal.fire({
          icon: "success",
          title: `${this.$t("editsuccess")} `,
          showConfirmButton: false,
          timer: 1500,
        }).then(() => {
          this.$router.push({
            path: "/config/user-group/index",
            query: {},
          });
        });
        this.loading = false;
      } else {
        Swal.fire({
          icon: "error",
          title: `${createResponse.message}`,
          showConfirmButton: false,
          timer: 1500,
        });
        this.loading = false;
      }
    },

    checkFormIsEmpty() {
      !this.form.firstName
        ? (this.isValid = false)
        : !this.form.lastName
        ? (this.isValid = false)
        : (this.isValid = true);
    },

    validateForm() {
      let forms = document.querySelectorAll(".needs-validation");
      Array.prototype.slice.call(forms).forEach(function (form) {
        form.addEventListener(
          "submit",
          function (event) {
            event.preventDefault();
            if (!form.checkValidity()) {
              event.stopPropagation();
            }
            form.classList.add("was-validated");
          },
          false
        );
      });
    },
    cancel() {
      this.$router.push({
        path: "/config/user-group/index",
        query: {},
      });
    },
  },
};
</script>
